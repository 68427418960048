const MEDIA_QUERY = '(max-width: 66.875rem)'
const MEDIA_QUERY_SMALL_SIZE = '(max-width: 35rem)'
let swiper

const handleTriggerClick = (triggers, contents, index) => () => {
    triggers.forEach((trigger) => trigger.classList.remove('tabs__trigger--active'))
    contents.forEach((content, contentIndex) => {
        content.classList.remove('tabs__content--active')

        if (index === contentIndex) {
            const activeTab = content.closest('.swiper')
            if (activeTab) {
                const tabWidth = activeTab.offsetWidth
                content.style.width = `${tabWidth}px`
            }

            content.classList.add('tabs__content--active')
            triggers[index].classList.add('tabs__trigger--active')
        }
    })
}

export default function tabs() {
    const tabEls = document.querySelectorAll('.tabs')
    tabEls.forEach((tab) => {
        const triggers = tab.querySelectorAll('.tabs__trigger')
        const contents = tab.querySelectorAll('.tabs__content')

        triggers.forEach((trigger, index) => {
            firefoxFixHeader(trigger)
            firefoxFixHeader(contents[index])

            trigger.addEventListener('click', handleTriggerClick(triggers, contents, index))
        })
    })

    function firefoxFixHeader(headerElem) {
        const header = headerElem.querySelector('h5')
        const text = header.innerHTML
        const replacedStr = text.replace(/\u2028/g, ' ')
        header.innerText = replacedStr
    }

    createSlider()

    window.addEventListener('resize', () => {
        createSlider()

        // Update tabWidth variable
        const activeTab = document.querySelector('.tabs__content--active')
        if (activeTab && window.matchMedia(MEDIA_QUERY_SMALL_SIZE).matches) {
            const closestSwiper = activeTab.closest('.swiper')
            if (closestSwiper) {
                const tabWidth = closestSwiper.offsetWidth

                // Update the width of each active tab content
                document.querySelectorAll('.tabs__content--active').forEach((content) => {
                    content.style.width = `${tabWidth}px`
                })
            }
        }
    })
}

function createSlider() {
    if (swiper) {
        swiper.destroy(true, true)
        resetSlideHeight()
    }

    if (!window.matchMedia(MEDIA_QUERY).matches) {
        return
    }

    // eslint-disable-next-line no-undef
    swiper = new Swiper('.tabs .swiper', {
        loop: true,
        autoHeight: false,
        spaceBetween: 10,
        slidesPerView: 1,
        initialSlide: 0,
        breakpoints: {
            560: {
                slidesPerView: 1.5,
            },
        },

        on: {
            init: function() {
                setEqualHeight(this.slides)
            },
            resize: function() {
                setEqualHeight(this.slides)
            },
        },
    })

    window.addEventListener('resize', debounce(() => {
        if (swiper) {
            setEqualHeight(swiper.slides)
        }
    }, 250))

    return swiper
}

function setEqualHeight(slides) {
    let tallest = 0
    slides.forEach((slide) => {
        const slideHeight = slide.offsetHeight
        if (slideHeight > tallest) {
            tallest = slideHeight
        }
    })

    slides.forEach((slide) => {
        slide.style.minHeight = `${tallest}px`
    })
}

function resetSlideHeight() {
    document.querySelectorAll('.swiper-slide').forEach((slide) => {
        slide.style.minHeight = ''
    })
}

function debounce(func, wait, immediate) {
    let timeout

    return function() {
        const context = this
        const args = arguments
        const later = function() {
            timeout = null
            if (!immediate) {
                func.apply(context, args)
            }
        }
        const callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) {
            func.apply(context, args)
        }
    }
}

